<template>
  <v-app-bar
    :class="{
      'pr-0': true,
      'z-99': true,
      'viewable-companies': viewableCompanySelector
    }"
    flat
    app
    clipped-left
    color="white"
    height="76"
  >
    <router-link :to="{ name: topLink }">
      <div class="logo">
        <mieru-logo />
      </div>
    </router-link>
    <v-divider vertical />
    <!-- よくみえーるリンク -->
    <div v-if="isYoku" class="left-navigation">
      <router-link
        class="left-navigation-link"
        :class="{
          'left-navigation-link-active': $route.meta.routeType === ROUTE_TYPE.analytics
        }"
        :to="{
          name: 'ShopAnalyticsVisitor',
          params: {
            id: $store.state.selectedStore ? $store.state.selectedStore.storeId : null
          }
        }"
      >
        店舗分析
      </router-link>
    </div>
    <v-divider v-if="isYoku" vertical />
    <div v-if="isYoku" class="left-navigation">
      <router-link
        class="left-navigation-link"
        :class="{
          'left-navigation-link-active': $route.meta.routeType === ROUTE_TYPE.compare
        }"
        :to="{
          name: 'StoreCompareVisitor',
          params: {
            id: $store.state.selectedComparisonGroup
              ? $store.state.selectedComparisonGroup.id
              : null
          }
        }"
      >
        店舗比較
      </router-link>
    </div>
    <v-divider v-if="isYoku" vertical />
    <div v-if="isYoku" class="left-navigation">
      <router-link
        class="left-navigation-link"
        :to="{ name: 'StoreArea' }"
        active-class="left-navigation-link-active"
      >
        店舗別シェア
      </router-link>
    </div>
    <v-divider v-if="isYoku" vertical />
    <!-- ひろくみえーるリンク -->
    <div v-if="isHiroku" class="left-navigation">
      <router-link
        class="left-navigation-link"
        :class="{
          'left-navigation-link-active': $route.meta.routeType === ROUTE_TYPE.chainAnalytics
        }"
        :to="{
          name: 'ChainAnalyticsVisitor',
          query: {
            period: startDate && endDate ? `${startDate}_${endDate}` : undefined,
            c_period:
              compareStartDate && compareEndDate
                ? `${compareStartDate}_${compareEndDate}`
                : undefined,
            unit: 'date',
            subIndicator: 'none',
            chainId: selectedChainId,
            prefectureIds: selectedPrefectureIdsOfChain
          }
        }"
      >
        チェーン分析
      </router-link>
    </div>
    <v-divider v-if="isHiroku" vertical />
    <div v-if="isHiroku" class="left-navigation">
      <router-link
        class="left-navigation-link"
        :class="{
          'left-navigation-link-active': $route.meta.routeType === ROUTE_TYPE.chainCompare
        }"
        :to="{
          name: 'ChainCompareVisitor',
          query: {
            period: startDate && endDate ? `${startDate}_${endDate}` : undefined,
            unit: 'date',
            chainIds: selectedChainIds,
            prefectureIds: selectedPrefectureIdsOfChains
          }
        }"
      >
        チェーン比較
      </router-link>
    </div>
    <v-divider v-if="isHiroku" vertical />
    <div v-if="isHiroku" class="left-navigation">
      <router-link
        class="left-navigation-link"
        :class="{
          'left-navigation-link-active': $route.meta.routeType === ROUTE_TYPE.trend
        }"
        :to="{ name: 'TrendStore' }"
      >
        トレンド
      </router-link>
    </div>
    <v-divider v-if="isHiroku" vertical />
    <v-spacer />
    <v-divider vertical />
    <div class="right-navigation">
      <div class="right-navigation-item">
        <v-tooltip text="お知らせ" location="bottom">
          <template #activator="{ props }">
            <router-link
              v-bind="props"
              class="right-navigation-link pa-3"
              :to="{ name: 'NewsList' }"
              active-class="right-navigation-link-active"
            >
              <img src="@/assets/svg/icon_news.svg" />
            </router-link>
          </template>
        </v-tooltip>
      </div>
      <div class="right-navigation-item">
        <v-tooltip text="ユーザー設定" location="bottom">
          <template #activator="{ props }">
            <router-link
              v-bind="props"
              class="right-navigation-link pa-3"
              :to="{ name: 'UserSetting' }"
              active-class="right-navigation-link-active"
            >
              <img src="@/assets/svg/icon_usersetting.svg" />
            </router-link>
          </template>
        </v-tooltip>
      </div>
    </div>
    <button id="hamburger" class="hamburger" @click="showDialog = true">
      <img src="@/assets/svg/menu.svg" />
    </button>
    <dialog-menu :show-dialog="showDialog" @close="showDialog = false" />
  </v-app-bar>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue'
import { useStore } from 'vuex'
import MieruLogo from '@/commons/components/SVG/MieruLogo.vue'
import DialogMenu from '@/commons/components/GlobalNavigation/DialogMenu.vue'
import { ROUTE_TYPE } from '@/commons/types/route'
import { convertHyphenDelimiter } from '@/commons/utils/dateUtil'
import { PRODUCT_FEATURE } from '@/commons/enums'
import { UserType } from '@/features/Dashboard/enums'

const showDialog = ref(false)
const store = useStore()
const isYoku = computed(() => store.getters.isYoku)
const isHiroku = computed(() => store.getters.isHiroku)
const startDate = computed<string | undefined>(() =>
  store.state.startDate ? convertHyphenDelimiter(store.state.startDate) : undefined
)
const endDate = computed<string | undefined>(() =>
  store.state.endDate ? convertHyphenDelimiter(store.state.endDate) : undefined
)
const compareStartDate = computed<string | undefined>(() =>
  store.state.compareStartDate ? convertHyphenDelimiter(store.state.compareStartDate) : undefined
)
const compareEndDate = computed<string | undefined>(() =>
  store.state.compareEndDate ? convertHyphenDelimiter(store.state.compareEndDate) : undefined
)
const selectedChainId = computed<string>(() => store.state.selectedChainId)
const selectedChainIds = computed<string[]>(() => store.state.selectedChainIds)
const selectedPrefectureIdsOfChain = computed<number[]>(
  () => store.state.selectedPrefectureIdsOfChain
)
const selectedPrefectureIdsOfChains = computed<number[]>(
  () => store.state.selectedPrefectureIdsOfChains
)

const topLink = computed<string>(() => {
  if (store.state.productFeatures.includes(PRODUCT_FEATURE.YOKU)) return 'Dashboard'
  else return 'ChainAnalyticsVisitor'
})

const viewableCompanySelector = computed<boolean>(() => {
  return (
    store.state.userType === UserType.FULL_ADMIN || store.state.userType === UserType.LIMITED_ADMIN
  )
})
</script>

<style scoped>
.v-toolbar__content {
  padding: 0;
}

.v-toolbar {
  min-width: 1013px !important;
}

.viewable-companies {
  top: 45px !important;
}

.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 240px;
  color: #0e182e;
}

.logo:hover {
  color: #3e4657;
}

.logo-mieru:hover,
.logo-mieru:focus {
  /* #3E4657 */
  filter: invert(26%) sepia(31%) saturate(423%) hue-rotate(183deg) brightness(85%) contrast(85%);
}

.v-divider {
  border-color: rgb(238, 238, 238);
  margin: 0 -1px;
  width: 1px;
  opacity: 1;
}

.left-navigation {
  width: 130px;
  font-size: 14px;
  font-weight: 600;
}

.left-navigation-link {
  color: #222;
  text-decoration: none;
}

.left-navigation-link:hover {
  color: #555;
}

.left-navigation-link-active {
  color: #4d99d0;
  text-decoration: underline;
  text-decoration-thickness: 2px;
  text-underline-offset: 3px;
}

.left-navigation-link-active:hover {
  color: #4d99d0;
  text-decoration: underline;
  text-decoration-thickness: 2px;
  text-underline-offset: 3px;
}

.right-navigation {
  display: flex;
  align-items: center;
  justify-content: right;
  padding: 0 21px;
  font-size: 14px;
  font-weight: 600;
}

.right-navigation-item {
  padding: 0 9.5px;
}

.right-navigation-link {
  color: #888;
  text-decoration: none;
}

.right-navigation-link:hover {
  color: #555;
  text-decoration: none;
}

.right-navigation-link-active {
  color: #4d99d0;
}

.right-navigation-link-active:hover {
  color: #4d99d0;
}

.hamburger {
  width: 77px;
  height: 77px;
  background-color: #0e182e;
}

.hamburger:hover {
  background-color: #3e4657;
}

.secondary-text {
  color: #888888;
}

.z-99 {
  z-index: 99 !important;
}
</style>

<style>
.v-toolbar__content {
  border-bottom: 1px solid #eeeeee;
}

.v-badge--dot .v-badge__badge {
  border-radius: 2.5px !important;
  min-width: 0;
  padding: 0;
  height: 5px !important;
  width: 5px !important;
}
</style>
