<template>
  <v-card height="'650px'">
    <v-card-title
      :class="
        chainIds.length === 0 || processedChartData.length <= 1
          ? 'pa-0 card-title d-flex align-center'
          : 'pa-0 mb-0 card-title d-flex align-center'
      "
    >
      <span>{{ props.chartKey === 'visitFrequency' ? '来店頻度' : 'エンゲージメント' }}</span>
      <alert-tooltip
        v-if="hasAlert"
        class="ml-2"
        text="取得データボリュームが少なく統計上の信頼性が低い日が含まれています。該当日は参考値としてご参照ください。"
      />
      <v-spacer />
      <chart-description-tooltip
        menu-key="chainCompare"
        sub-menu-key="visitEngagement"
        :chart-key="props.chartKey"
      />
      <download-button
        label="CSVデータ"
        :disabled="!props.chainIds"
        :get-file-id="getFileId"
        :csv-name="chartCsvName"
        class="download-button"
      />
    </v-card-title>
    <v-container class="pa-0" fluid>
      <v-row class="my-0" dense>
        <v-col class="py-0">
          <div v-show="props.chainIds.length === 0" class="unselected_card">
            チェーンを選択するとチャートが表示されます。
          </div>
          <div v-show="props.chainIds.length > 0">
            <loadingImg v-if="chartLoading" :height="'200px'" />
            <no-data-chart v-else-if="processedChartData.length <= 1" />
            <div v-else>
              <GChart
                ref="centerBarChart"
                type="BarChart"
                :data="processedChartData"
                :options="visitPatternChartOptions"
              />
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row v-if="props.chainIds.length > 0" class="my-2" dense>
        <v-col>
          <div class="my-0 chart-legend">
            <chart-legend :legends="chartLegends" />
            <v-spacer />
            <alert-caption v-if="hasAlert" variants="square" />
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import { GChart } from 'vue-google-charts'
import NoDataChart from '@/commons/components/Chart/NoDataChart.vue'
import { COLOR } from '@/commons/enums'
import LoadingImg from '@/commons/components/loadingImg.vue'
import ChartLegend from '@/features/ChainCompare/visitPattern/components/ChartLegend.vue'
import { AxiosResponse } from 'axios'
import { Chain } from '@/commons/interfaces'

import { useStore } from 'vuex'
const store = useStore()
const chains = computed<Chain[]>(() => store.getters.chains)
const startMonth = computed<string>(() => store.state.startMonth)
const endMonth = computed<string>(() => store.state.endMonth)
const monthlyPeriodStart = ref(store.state.monthlyPeriodStart)
const monthlyPeriodEnd = ref(store.state.monthlyPeriodEnd)

const props = withDefaults(
  defineProps<{
    chainIds?: string[]
    prefectureIds?: number[]
    chartKey: 'visitFrequency' | 'engagement'
  }>(),
  {
    chainIds: () => [],
    prefectureIds: () => [],
    chartKey: 'visitFrequency'
  }
)

import {
  FrequencyResponse,
  EngagementResponse
} from '@/features/ChainCompare/visitPattern/interface/response'

import {
  getVisitFrequencyChart,
  getEngagementChart
} from '@/features/ChainAnalytics/visitPattern/axios'
import {
  downloadVisitFrequencyChart,
  downloadEngagementChart
} from '@/features/ChainCompare/visitPattern/axios'
import { convertHyphenDelimiter } from '@/commons/utils/dateUtil'
import * as notify from '@/plugins/notification'
import {
  processFrequencyChart,
  processEngagementChart
} from '@/features/ChainCompare/visitPattern/utils'

type FrequencyGraph = FrequencyResponse & { orderIndex: number; chainName: string }

type EngagementGraph = EngagementResponse & { orderIndex: number; chainName: string }

const chartData = ref<(string | number | boolean | unknown)[][]>([])
const chartLoading = ref<boolean>(true)
const chartController = ref(new AbortController())
const chartLegends = computed<{ text: string; color: string }[]>(() => {
  if (props.chartKey === 'visitFrequency') {
    return [
      { text: '月1回', color: COLOR.RED },
      { text: '月2回', color: COLOR.BLUE },
      { text: '月3回', color: COLOR.GREEN },
      { text: '月4回以上', color: COLOR.ORANGE },
      { text: '月8回以上', color: COLOR.PURPLE },
      { text: '参考値（データ不十分）', color: COLOR.GRAY }
    ]
  } else {
    return [
      { text: '新規', color: COLOR.RED },
      { text: '継続', color: COLOR.BLUE },
      { text: '復活', color: COLOR.GREEN },
      { text: '離反（当月）', color: COLOR.ORANGE },
      { text: '離反（先月）', color: COLOR.PURPLE },
      { text: '参考値（データ不十分）', color: COLOR.GRAY }
    ]
  }
})
const getFileId = async () => {
  if (props.chartKey === 'visitFrequency') {
    return downloadVisitFrequencyChart(
      props.chainIds,
      props.prefectureIds.map((id: number) => id.toString()),
      startMonth.value,
      endMonth.value
    )
  } else {
    return downloadEngagementChart(
      props.chainIds,
      props.prefectureIds.map((id: number) => id.toString()),
      startMonth.value,
      endMonth.value
    )
  }
}
const chartCsvName = computed<string>(() => {
  if (props.chartKey === 'visitFrequency')
    return `チェーン比較_来店頻度_${monthlyPeriodStart.value}-${monthlyPeriodEnd.value}`
  else return `チェーン比較_エンゲージメント_${monthlyPeriodStart.value}-${monthlyPeriodEnd.value}}`
})

const processedChartData = computed(() => {
  // 各店舗ごとの配列の最後にはアラートフラグが入っているので除く
  return chartData.value.map((storeVisitPattern: unknown[]) => {
    return storeVisitPattern.slice(0, -1)
  })
})
const hasAlert = computed<boolean>(() => {
  return chartData.value
    .map((storeVisitPattern: unknown[]) => storeVisitPattern.slice(-1)[0] as boolean)
    .reduce((previous, current) => previous || current, false)
})
const visitPatternChartOptions = computed(() => {
  return {
    chartArea: {
      top: 30,
      left: 140,
      right: 20,
      bottom: 30
    },
    colors: [COLOR.RED, COLOR.BLUE, COLOR.GREEN, COLOR.ORANGE, COLOR.PURPLE, COLOR.GRAY],
    hAxis: {
      textStyle: {
        fontSize: 13
      },
      format: '###.#%'
    },
    legend: { position: 'none' },
    isStacked: 'percent',
    tooltip: {
      isHtml: true
    },
    height: 80 + 39 * props.chainIds.length,
    bar: { groupWidth: '17' },
    vAxis: {
      textStyle: {
        fontSize: 12
      }
    }
  }
})

function fetchChartData() {
  chartLoading.value = true
  chartData.value.splice(0, 0)

  if (props.chartKey === 'visitFrequency') fetchFrequencyChart()
  else fetchEngagementChart()
}

async function fetchFrequencyChart() {
  // 選択済みのチェーンの順番（props.chainIds の順番）を保持するためのマップオブジェクト
  const frequencyGraphsMap = new Map<string, FrequencyGraph>()

  await Promise.all(
    props.chainIds.map(async (chainId: string, index: number) => {
      await getVisitFrequencyChart(
        chainId,
        props.prefectureIds.map((id: number) => id.toString()),
        convertHyphenDelimiter(startMonth.value),
        convertHyphenDelimiter(endMonth.value),
        chartController.value.signal
      ).then((res: AxiosResponse<FrequencyResponse>) => {
        const chain = chains.value.find((chain: Chain) => chain.id === chainId)
        if (chain) {
          frequencyGraphsMap.set(chainId, {
            // NOTE: チェーンのランク順ではなく選択順とする。
            orderIndex: index,
            chainName: chain.name,
            visitCounts: res.data.visitCounts
          })
        }
      })
    })
  )
    .then(() => {
      // 選択済みのチェーンの順番で Response を格納
      const frequencyGraphs: FrequencyGraph[] = props.chainIds
        .map((chainId) => frequencyGraphsMap.get(chainId))
        .filter((res) => !!res) as FrequencyGraph[]
      if (frequencyGraphs.length > 0) chartData.value = processFrequencyChart(frequencyGraphs)
    })
    .catch((e) => {
      console.error(e)
      notify.notifyErrorMessage('来店頻度が表示できませんでした。')
    })
    .finally(() => {
      chartLoading.value = false
    })
}

async function fetchEngagementChart() {
  // 選択済みのチェーンの順番（props.chainIds の順番）を保持するためのマップオブジェクト
  const engagementGraphsMap = new Map<string, EngagementGraph>()

  await Promise.all(
    props.chainIds.map(async (chainId: string, index: number) => {
      await getEngagementChart(
        chainId,
        props.prefectureIds.map((id: number) => id.toString()),
        convertHyphenDelimiter(startMonth.value),
        convertHyphenDelimiter(endMonth.value),
        chartController.value.signal
      ).then((res: AxiosResponse<EngagementResponse>) => {
        const chain = chains.value.find((chain: Chain) => chain.id === chainId)
        if (chain) {
          engagementGraphsMap.set(chainId, {
            // NOTE: チェーンのランク順ではなく選択順とする。
            orderIndex: index,
            chainName: chain.name,
            visitCounts: res.data.visitCounts
          })
        }
      })
    })
  )
    .then(() => {
      const engagementGraphs: EngagementGraph[] = props.chainIds
        .map((chainId) => engagementGraphsMap.get(chainId))
        .filter((res) => !!res) as EngagementGraph[]
      chartData.value = processEngagementChart(engagementGraphs)
    })
    .catch((e) => {
      console.error(e)
      notify.notifyErrorMessage('エンゲージメントが表示できませんでした。')
    })
    .finally(() => {
      chartLoading.value = false
    })
}

const isReadyToFetch = () => {
  return props.chainIds.length > 0 && props.prefectureIds.length > 0 && chains.value.length > 0
}

/* --------------------------------------------------------------------------
  created
 ---------------------------------------------------------------------------*/

if (isReadyToFetch()) {
  fetchChartData()
}

/* --------------------------------------------------------------------------
  watch
 ---------------------------------------------------------------------------*/

watch(
  () => [props.chainIds, props.prefectureIds, chains],
  () => {
    if (isReadyToFetch()) {
      fetchChartData()
    }
  },
  { deep: true }
)
</script>

<style lang="scss" scoped>
.v-btn--active {
  color: #fff !important;
  background: #0e182e !important;
}

/* TODO: 一箇所で統一する */
.square {
  display: inline-block;
  margin-right: 6px;
  width: 10px;
  height: 10px;
  border: 2px solid #666;
  background: #eeeeee;
}

.chart-legend {
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #666;
}

.download-button {
  display: flex;
  align-items: center;
  margin-left: 29.4px;
}
</style>
