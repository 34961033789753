<template>
  <div class="monthly-date-picker">
    <button
      ref="monthDatePicker"
      class="selector"
      aria-label="基準月"
      @click="monthOpen = !monthOpen"
    >
      <span>{{ month?.text }}</span>
      <img
        class="icon"
        src="@/assets/svg/triangle-down.svg"
      >
      <transition name="fade">
        <ul
          v-show="monthOpen"
          class="list"
        >
          <li
            v-for="item in months"
            :key="item.value"
            class="item"
            :class="{
              'disabled-list': item.disabled,
            }"
            @click="setmonth(item)"
          >
            {{ item.text }}
          </li>
        </ul>
      </transition>
    </button>
  </div>
</template>

<script setup lang="ts">
import { onBeforeUnmount, onMounted, ref, watch, computed } from "vue";
import moment from "moment";
import { useStore } from 'vuex'
/* --------------------------------------------------------------------------
  Vuex
 ---------------------------------------------------------------------------*/

const store = useStore()
const startMonth = computed<string>(() => store.state.monthlyPeriodStart)
const endMonth = computed<string>(() => store.state.monthlyPeriodEnd)


/* --------------------------------------------------------------------------

 ---------------------------------------------------------------------------*/
type Month = { value: string; text: string; disabled?: boolean };
const props = withDefaults(
  defineProps<{
    date: string
  }>(), {
    date: undefined
  },
)
const emits = defineEmits(["update-period"])

const monthOpen = ref<boolean>(false)
const month = ref<Month>({
  value: moment(endMonth.value, "YYYY-MM-DD").startOf('month').format("YYYY-MM-DD"),
  text: moment(endMonth.value, "YYYY-MM-DD").format("YYYY年MM月"),
})
const months = ref<Month[]>([])

function setmonth(item: { value: string; text: string; disabled?: boolean }) {
  month.value = {
    value: item.value,
    text: item.text,
  };
}
const monthDatePicker = ref<HTMLImageElement>()
function close(event: any) {
  if (monthDatePicker.value && !monthDatePicker.value.contains(event.target))
    monthOpen.value = false;
}
function updatePeriod() {
  emits("update-period", month.value.value);
}

const propsObj = ref(props)
watch(
  () => propsObj.value.date,
  (newVal) => {
    month.value = {
      value: newVal,
      text: moment(newVal).format("YYYY年MM月"),
    };
  }
)
watch(
  month,
  (newVal, oldVal) => {
  if (newVal.value !== oldVal.value) updatePeriod()
})
watch(
  monthOpen,
  () => {
    const selectedStartMonth = months.value.find((m) => m.value === month.value.value);
    if (!selectedStartMonth) return;
    months.value = [
      months.value.find(
        (m) => m.value === month.value.value
      ) as Month,
      ...months.value
        .filter((m) => m.value !== month.value.value)
        .sort((a, b) => (a.value < b.value ? 1 : -1)),
    ].sort((a, b) => Number(a.disabled) - Number(b.disabled));
  }
)

function init() {
  months.value.splice(0);
  const startPeriod = moment(startMonth.value, 'YYYY/MM/DD');
  const endPeriod = moment(endMonth.value, 'YYYY/MM/DD');
  let currentPeriod = startPeriod.clone();

  while (currentPeriod.isSameOrBefore(endPeriod, 'month')) {
    months.value.push({
      value: currentPeriod.startOf("month").format("YYYY-MM-DD"),
      text: currentPeriod.startOf("month").format("YYYY年MM月"),
      disabled: false,
    });
    currentPeriod.add(1, 'month');
  }
}
init();

onMounted(() => window.addEventListener("click", close))
onBeforeUnmount(() => window.removeEventListener("click", close))
</script>

<style lang="scss" scoped>
.monthly-date-picker {
  display: flex;
  align-items: center;

  .selector {
    position: relative;
    display: flex;
    align-items: center;
    padding: 9px 10px 10px 21px;
    min-width: 130px;
    height: 38px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background: #fff;
    font-size: 13px;
    cursor: pointer;

    .icon {
      margin-top: 3px;
      margin-left: auto;
    }

    .list {
      position: absolute;
      left: -1px;
      top: -2px;
      padding: 0;
      list-style: none;
      min-width: 130px;
      border: 1px solid #ccc;
      border-radius: 4px;
      background: #fff;
      z-index: 5;

      .item {
        padding: 9px 5px 10px 21px;
        min-width: 130px;
        height: 38px;
        text-align: left;
      }
      .item:hover {
        background-color: #f5f5f5;
      }
    }

    .selected-list {
      background: #f5f5f5;
    }
    .disabled-list {
      cursor: not-allowed;
      color: #9e9e9e;
      pointer-events: none;
    }
  }

  .selector-disabled {
    background: #e5e5e5;
    border-color: #e5e5e5;
    pointer-events: none;
  }
}

/* animation */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
