import { axiosClient } from "@/plugins/axios";
import { AxiosResponse } from "axios";
import { convertHyphenDelimiter } from "@/commons/utils/dateUtil";
import {
  FrequencyResponse,
  EngagementResponse,
  CohortResponse,
} from "@/features/ShopAnalytics/interfaces/response";
import { DownloadResponse } from "@/commons/interfaces/responses/download";

/**
 * 来店頻度チャート取得
 * @param {string} storeId 店舗ID
 * @param {string} start 期間開始日(yyyy-MM-dd)
 * @param {string} end 期間終了日(yyyy-MM-dd)
 * @param {AbortSignal} signal APIの中断用オブジェクト
 */
export function getVisitFrequencyChart(
  storeId: string | undefined,
  start: string,
  end: string,
  signal?: AbortSignal
): Promise<AxiosResponse> {
  if (typeof storeId === "undefined") return Promise.reject();
  return axiosClient.get<FrequencyResponse>("/stores/chart/visit-frequency/", {
    params: {
      store: storeId,
      start: convertHyphenDelimiter(start),
      end: convertHyphenDelimiter(end),
    },
    signal: signal,
  });
}

/**
 * 来店頻度チャートDL用URL生成
 * @param {string} storeId 店舗ID
 * @param {string} start 期間開始日(yyyy-MM-dd)
 * @param {string} end 期間終了日(yyyy-MM-dd)
 */
export function downloadVisitFrequencyChart(
  storeId: string | undefined,
  start: string,
  end: string,
): Promise<AxiosResponse> {
  if (typeof storeId === "undefined") return Promise.reject();
  return axiosClient.get<DownloadResponse>(
    "/stores/chart/visit-frequency/download/",
    {
      params: {
        store: storeId,
        start: convertHyphenDelimiter(start),
        end: convertHyphenDelimiter(end),
      },
    }
  );
}

/**
 * エンゲージメントチャート取得
 * @param {string} storeId 店舗ID
 * @param {string} start 期間開始日(yyyy-MM-dd)
 * @param {string} end 期間終了日(yyyy-MM-dd)
 * @param {AbortSignal} signal APIの中断用オブジェクト
 */
export function getEngagementChart(
  storeId: string | undefined,
  start: string,
  end: string,
  signal?: AbortSignal
): Promise<AxiosResponse> {
  if (typeof storeId === "undefined") return Promise.reject();
  // NOTE: 期間は13ヶ月間固定
  return axiosClient.get<EngagementResponse>("/stores/chart/engagement/", {
    params: {
      store: storeId,
      start: convertHyphenDelimiter(start),
      end: convertHyphenDelimiter(end),
    },
    signal: signal,
  });
}

/**
 * エンゲージメントチャートDL用URL生成
 * 期間は13ヶ月間固定
 * @param {string} storeId 店舗ID
 * @param {string} start 期間開始日(yyyy-MM-dd)
 * @param {string} end 期間終了日(yyyy-MM-dd)
 */
export function downloadEngagementChart(
  storeId: string | undefined,
  start: string,
  end: string,
): Promise<AxiosResponse> {
  if (typeof storeId === "undefined") return Promise.reject();
  // NOTE: 期間は13ヶ月間固定
  return axiosClient.get<DownloadResponse>(
    "/stores/chart/engagement/download/",
    {
      params: {
        store: storeId,
        start: convertHyphenDelimiter(start),
        end: convertHyphenDelimiter(end),
      },
    }
  );
}

/**
 * コホート分析チャート取得
 * 期間は13ヶ月間固定
 * @param {string} storeId 店舗ID
 */
export function getCohortAnalysisChart(
  storeId: string | undefined
): Promise<AxiosResponse> {
  if (typeof storeId === "undefined") return Promise.reject();
  return axiosClient.get<CohortResponse>("/stores/chart/cohort-analysis/", {
    params: {
      store: storeId,
    },
  });
}

/**
 * コホート分析チャートDL用URL生成
 * 期間は13ヶ月間固定
 * @param {string} storeId 店舗ID
 */
export function downloadCohortAnalysisChart(
  storeId: string | undefined
): Promise<AxiosResponse> {
  if (typeof storeId === "undefined") return Promise.reject();
  return axiosClient.get<DownloadResponse>(
    "/stores/chart/cohort-analysis/download/",
    {
      params: {
        store: storeId,
      },
    }
  );
}
