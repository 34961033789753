import { axiosClient } from '@/plugins/axios'
import { AxiosResponse } from 'axios'
import { convertHyphenDelimiter } from '@/commons/utils/dateUtil'
import {
  FrequencyResponse,
  EngagementResponse,
  CohortResponse
} from '@/features/ShopAnalytics/interfaces/response'
import { DownloadResponse } from '@/commons/interfaces/responses/download'

/**
 * 来店頻度チャート取得
 * @param {string} chainId チェーンID
 * @param {string[]} prefectureIds 都道府県IDs
 * @param {string} start 期間開始日(yyyy-MM-dd)
 * @param {string} end 期間終了日(yyyy-MM-dd)
 * @param {AbortSignal} signal APIの中断用オブジェクト
 */
export function getVisitFrequencyChart(
  chainId: string | undefined,
  prefectureIds: string[] | undefined,
  start: string | undefined,
  end: string | undefined,
  signal?: AbortSignal
): Promise<AxiosResponse<FrequencyResponse>> {
  if (!chainId) return Promise.reject()
  return axiosClient.get<FrequencyResponse>('/chains/chart/visit-frequency/', {
    params: {
      chainId,
      prefectureIds,
      start: convertHyphenDelimiter(start),
      end: convertHyphenDelimiter(end)
    },
    signal: signal
  })
}

/**
 * 来店頻度チャートDL用URL生成
 * @param {string} chainId チェーンID
 * @param {string[]} prefectureIds 都道府県IDs
 * @param {string} start 期間開始日(yyyy-MM-dd)
 * @param {string} end 期間終了日(yyyy-MM-dd)
 */
export function downloadVisitFrequencyChart(
  chainId: string | undefined,
  prefectureIds: string[] | undefined,
  start: string | undefined,
  end: string | undefined,
): Promise<AxiosResponse<DownloadResponse>> {
  if (!chainId) return Promise.reject()
  return axiosClient.get<DownloadResponse>('/chains/chart/visit-frequency/download/', {
    params: {
      chainId,
      prefectureIds,
      start: convertHyphenDelimiter(start),
      end: convertHyphenDelimiter(end)
    }
  })
}

/**
 * エンゲージメントチャート取得
 * @param {string} chainId チェーンID
 * @param {string[]} prefectureIds 都道府県IDs
 * @param {string} start 期間開始日(yyyy-MM-dd)
 * @param {string} end 期間終了日(yyyy-MM-dd)
 * @param {AbortSignal} signal APIの中断用オブジェクト
 */
export function getEngagementChart(
  chainId: string | undefined,
  prefectureIds: string[] | undefined,
  start: string | undefined,
  end: string | undefined,
  signal?: AbortSignal
): Promise<AxiosResponse<EngagementResponse>> {
  if (!chainId) return Promise.reject()
  return axiosClient.get<EngagementResponse>('/chains/chart/engagement/', {
    params: {
      chainId,
      prefectureIds,
      start: convertHyphenDelimiter(start),
      end: convertHyphenDelimiter(end)
    },
    signal: signal
  })
}

/**
 * エンゲージメントチャートDL用URL生成
 * @param {string} chainId チェーンID
 * @param {string[]} prefectureIds 都道府県IDs
 * @param {string} start 期間開始日(yyyy-MM-dd)
 * @param {string} end 期間終了日(yyyy-MM-dd)
 */
export function downloadEngagementChart(
  chainId: string | undefined,
  prefectureIds: string[] | undefined,
  start: string | undefined,
  end: string | undefined,
): Promise<AxiosResponse<DownloadResponse>> {
  if (!chainId) return Promise.reject()
  return axiosClient.get<DownloadResponse>('/chains/chart/engagement/download/', {
    params: {
      chainId,
      prefectureIds,
      start: convertHyphenDelimiter(start),
      end: convertHyphenDelimiter(end)
    }
  })
}

/**
 * コホート分析チャート取得
 * 期間は13ヶ月間固定
 * @param {string} chainId チェーンID
 * @param {string[]} prefectureIds 都道府県IDs
 */
export function getCohortAnalysisChart(
  chainId: string | undefined,
  prefectureIds: string[] | undefined
): Promise<AxiosResponse<CohortResponse>> {
  if (!chainId) return Promise.reject()
  return axiosClient.get<CohortResponse>('/chains/chart/cohort-analysis/', {
    params: {
      chainId,
      prefectureIds
    }
  })
}

/**
 * コホート分析チャートDL用URL生成
 * 期間は13ヶ月間固定
 * @param {string} chainId チェーンID
 * @param {string[]} prefectureIds 都道府県IDs
 */
export function downloadCohortAnalysisChart(
  chainId: string | undefined,
  prefectureIds: string[] | undefined
): Promise<AxiosResponse<DownloadResponse>> {
  if (!chainId) return Promise.reject()
  return axiosClient.get<DownloadResponse>('/chains/chart/cohort-analysis/download/', {
    params: {
      chainId,
      prefectureIds
    }
  })
}
